import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'intropage', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'questionspage', loadChildren: './questionspage/questionspage.module#QuestionspagePageModule' },
  { path: 'playerpage', loadChildren: './playerpage/playerpage.module#PlayerpagePageModule' },
  { path: 'statspage', loadChildren: './statspage/statspage.module#StatspagePageModule' },
  { path: 'loginpage', loadChildren: './loginpage/loginpage.module#LoginpagePageModule' },
  { path: 'registerpage', loadChildren: './registerpage/registerpage.module#RegisterpagePageModule' },
  { path: 'intropage', loadChildren: './intropage/intropage.module#IntropagePageModule' },
  { path: 'gamepage', loadChildren: './gamepage/gamepage.module#GamepagePageModule' },
  { path: 'answerpage', loadChildren: './answerpage/answerpage.module#AnswerpagePageModule' },
  { path: 'resetpasspage', loadChildren: './resetpasspage/resetpasspage.module#ResetpasspagePageModule' },
  { path: 'pdfpage', loadChildren: './pdfpage/pdfpage.module#PdfpagePageModule' },
  { path: 'staredlistpage', loadChildren: './staredlistpage/staredlistpage.module#StaredlistpagePageModule' },
  { path: 'questionpreviewpage', loadChildren: './questionpreviewpage/questionpreviewpage.module#QuestionpreviewpagePageModule' },
  { path: 'lessonselectpage', loadChildren: './lessonselectpage/lessonselectpage.module#LessonselectpagePageModule' },
  { path: 'creditspage', loadChildren: './creditspage/creditspage.module#CreditspagePageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
